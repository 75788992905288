import React, { useState, useEffect } from "react";
import { AppBar, Box, Toolbar } from "@mui/material";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import logo from "../assets/logo/alamar.png";
import "../App.css";

// 📌 Stockage des images dans le frontend
const aboutImages = [
  { image: require("../assets/images/about/about_5x7+(5).webp"), color: "Blue" },
  { image: require("../assets/images/about/about_5x7+(7).webp"), color: "Blue" },
  { image: require("../assets/images/about/about_5x7+(10).webp"), color: "Red" },
  { image: require("../assets/images/about/about_5x7+(13).webp"), color: "Yellow" },
  { image: require("../assets/images/about/about_5x7+(15).webp"), color: "Yellow" },
  { image: require("../assets/images/about/about_5x7+(17).webp"), color: "Pink" },
  { image: require("../assets/images/about/about_5x7+(18).webp"), color: "Pink" },
  { image: require("../assets/images/about/about_5x7+(24).webp"), color: "Orange" },
  { image: require("../assets/images/about/about_5x7+(26).webp"), color: "Orange" },
  { image: require("../assets/images/about/about_5x7+(27).webp"), color: "Blue" },
  { image: require("../assets/images/about/about_5x7+(30).webp"), color: "Green" },
  { image: require("../assets/images/about/about_5x7+(33).webp"), color: "Green" },
  { image: require("../assets/images/about/about_5x7+(37).webp"), color: "Green" },
  { image: require("../assets/images/about/about_5x7+(40).webp"), color: "Green" },
  { image: require("../assets/images/about/about_5x7+(47).webp"), color: "Green" },
  { image: require("../assets/images/about/about_5x7+(48).webp"), color: "Green" }
];

const shopImages = [
  { image: require("../assets/images/shop/shop_5x7+(6).webp"), color: "Red" },
  { image: require("../assets/images/shop/shop_5x7+(8).webp"), color: "Red" },
  { image: require("../assets/images/shop/shop_5x7+(10).webp"), color: "Red" },
  { image: require("../assets/images/shop/shop_5x7+(14).webp"), color: "Yellow" },
  { image: require("../assets/images/shop/shop_5x7+(15).webp"), color: "Yellow" },
  { image: require("../assets/images/shop/shop_5x7+(16).webp"), color: "Yellow" },
  { image: require("../assets/images/shop/shop_5x7+(19).webp"), color: "Pink" },
  { image: require("../assets/images/shop/shop_5x7+(23).webp"), color: "Orange" },
  { image: require("../assets/images/shop/shop_5x7+(25).webp"), color: "Orange" },
  { image: require("../assets/images/shop/shop_5x7+(27).webp"), color: "Orange" },
  { image: require("../assets/images/shop/shop_5x7+(32).webp"), color: "Blue" },
  { image: require("../assets/images/shop/shop_5x7+(40).webp"), color: "Green" }
];

// 📌 Mélange les images et empêche les couleurs consécutives
const generatePairs = () => {
  let aboutCopy = [...aboutImages];
  let shopCopy = [...shopImages];

  const shuffleArray = (arr) => arr.sort(() => Math.random() - 0.5);

  aboutCopy = shuffleArray(aboutCopy);
  shopCopy = shuffleArray(shopCopy);

  let pairedImages = [];
  let lastAboutColor = null;
  let lastShopColor = null;

  while (aboutCopy.length > 0 && shopCopy.length > 0) {
    let aboutImage = aboutCopy.find((img) => img.color !== lastAboutColor) || aboutCopy[0];
    let shopImage = shopCopy.find((img) => img.color !== lastShopColor) || shopCopy[0];

    pairedImages.push({ about: aboutImage, shop: shopImage });

    lastAboutColor = aboutImage.color;
    lastShopColor = shopImage.color;

    aboutCopy = aboutCopy.filter((img) => img !== aboutImage);
    shopCopy = shopCopy.filter((img) => img !== shopImage);
  }

  return pairedImages;
};

const DesktopHomePageTest = () => {
  const [imageData, setImageData] = useState([]);

  useEffect(() => {
    setImageData(generatePairs());
  }, []);

  return (
    <>
      {imageData.length > 0 && (
        <>
          <AppBar position="absolute" sx={{ color: "#000", boxShadow: "none", top: 0, left: 0, right: 0, background: "transparent" }}>
            <Toolbar sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingX: { xs: 1, sm: 2, md: 4 } }}>
              <a href="https://alamardesigns.com/about-us" style={{ textDecoration: "none" }}>
                <span style={{ cursor: "pointer", color: "white", letterSpacing: "2px", fontSize: "clamp(12px, 1.5vw, 18px)" }}>ABOUT</span>
              </a>
              <Box component="img" src={logo} alt="Alamar Logo" sx={{ width: { xs: "80px", sm: "100px", md: "140px" }, height: { xs: "40px", sm: "50px", md: "70px" } }} />
              <a href="https://alamardesigns.com/shop/" style={{ textDecoration: "none" }}>
                <span style={{ cursor: "pointer", color: "white", letterSpacing: "2px", fontSize: "clamp(12px, 1.5vw, 18px)" }}>SHOP</span>
              </a>
            </Toolbar>
          </AppBar>

          <div className="page-container" style={{ height: "100vh", width: "100vw" }}>
            <div className="image-row" style={{ height: "100%", display: "flex", width: "100vw" }}>
              <div className="left-image" style={{ flex: 1, height: "100%" }}>
                <Swiper direction={"vertical"} loop={true} autoplay={{ delay: 7000, disableOnInteraction: false }} speed={5000} modules={[Autoplay]} style={{ width: "100%", height: "100%" }}>
                  {imageData.map((pair, index) => (
                    <SwiperSlide key={index} style={{ height: "100%" }}>
                      <a href="https://alamardesigns.com/about-us">
                        <img src={pair.about.image} alt="about" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                      </a>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>

              <div className="right-image" style={{ flex: 1, height: "100%" }}>
                <Swiper direction={"vertical"} loop={true} autoplay={{ delay: 7000, disableOnInteraction: false }} speed={5000} modules={[Autoplay]} style={{ width: "100%", height: "100%", transform: "rotate(180deg)" }}>
                  {imageData.map((pair, index) => (
                    <SwiperSlide key={index} style={{ height: "100%" }}>
                      <a href="https://alamardesigns.com/shop/">
                        <img src={pair.shop.image} alt="shop" style={{ width: "100%", height: "100%", objectFit: "cover", transform: "rotate(180deg)" }} />
                      </a>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DesktopHomePageTest;
