import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import DesktopHomePage from './component/desktopHomePage';
import MobileHomePage from './component/mobileHomePage';



const App = () => {
  const [deviceType, setDeviceType] = useState('mobile');

  useEffect(() => {
    const updateDeviceType = () => {
      if (window.innerWidth <= 768) {
        setDeviceType('mobile');
      } else {
        setDeviceType('desktop');
      }
    };

    updateDeviceType();

    window.addEventListener('resize', updateDeviceType);

    return () => window.removeEventListener('resize', updateDeviceType);
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={deviceType === "mobile" ? <MobileHomePage /> : <DesktopHomePage />} />
      </Routes>
    </Router>



  );
};

export default App;
